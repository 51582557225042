<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Gerätemanagement</div>
          </v-col>
          <v-col cols="12" sm="9" md="5" lg="4">
            <v-text-field v-model="id" label="ID"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="2" lg="2">
            <v-btn @click="find()" color="primary">ausleihen</v-btn>
          </v-col>
          <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
            <v-text-field v-model="search" append-icon="search" label="Suche"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="25"
              :footer-props="{
    'items-per-page-options': [10, 25, 50, 100,-1]
  }"
              :search="search"
            >
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="$router.push('/employee/' + item.id)"
                        fab
                        small
                        color="primary"
                      >
                        <i class="material-icons">settings</i>
                      </v-btn>
                    </template>
                    <span>Mitarbeiter bearbeiten</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="openAssignDialog(item)" fab small color="primary">
                        <i class="material-icons">perm_identity</i>
                      </v-btn>
                    </template>
                    <span>Ausleiher zuweisen</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="getEquipmentHistory(item.id)"
                        small
                        fab
                        color="primary"
                      >
                        <i class="material-icons">alarm_on</i>
                      </v-btn>
                    </template>
                    <span>Gerät Historie anzeigen</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="confirmDeleteItem(item.id,item.name)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Gerät löschen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.rented_at="{ item }">
                <div>{{$formatDateTime(item.rented_at)}}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Gerät ausleihen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <div v-if="item.rented_by">
                    <span style="color:red">Das Gerät liegt aktuell bei "{{item.name}}".</span><br> Möchten Sie wirklich das Gerät
                    <b>{{item.brand}} {{item.model}} ({{item.seriennummer}})</b> ausleihen?
                  </div>
                  <div v-else>
                    Möchten Sie wirklich das Gerät
                    <b>{{item.brand}} {{item.model}} ({{item.seriennummer}})</b> ausleihen?
                  </div>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="assign()">Ja</v-btn>
            <v-btn color="error" text @click="dialog = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Gerät löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie das Gerät
            <b>{{deleteName}}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="assigndialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <h3
              class="center"
            >Gerät {{selectedItem.brand}} {{selectedItem.model}} ({{selectedItem.seriennummer}})</h3>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-select
                      clearable
                      v-model="selectedItem.rented_by"
                      :items="users"
                      item-text="name"
                      item-value="id"
                      label="Person"
                      @change="changeName"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="assignUser()">Zuweisen</v-btn>
            <v-btn color="error" text @click="assigndialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      assigndialog: false,
      history: false,
      id: "",
      deleteId: "",
      search: "",
      userid: "",
      items: [],
      item: {},
      users: [],
      histories: [],
      selectedItem: {},
      newEquipment: {
        brand: "",
        model: "",
        description: "",
        seriennummer: ""
      },
      headershistory: [
        {
          text: "Geliehen von",
          value: "name"
        },
        {
          text: "Geliehen am",
          align: "center",
          value: "rented_at"
        },
        {
          text: "Zürück am",
          align: "center",
          value: "returned_at"
        }
      ],
      headers: [
        {
          text: "ID",
          value: "id"
        },
        {
          text: "Marke",
          value: "brand"
        },
        {
          text: "Modell",
          value: "model"
        },
        {
          text: "Bezeichnung",
          value: "description"
        },
        {
          text: "Seriennummer",
          value: "seriennummer"
        },
        {
          text: "Geliehen von",
          value: "name"
        },
        {
          text: "Geliehen am",
          value: "rented_at"
        },
        {
          text: "Aktion",
          value: "action"
        }
      ],
      rules: {
        required: value => !!value || "Required."
      }
    };
  },
  mounted() {
    this.getEquipments();
  },
  methods: {
    getEquipments() {
      this.$http({
        method: "post",
        url: "getEquipmentsByUser.php"
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success && response.data.data) {
            this.items = response.data.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    find() {
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getEquipment.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success && response.data.data) {
            this.item = response.data.data[0];
            this.dialog = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    assign() {
      let item = this.item;
      let formData = new FormData();
      formData.append("equipmentid", item.id);
      const userid = this.$store.getters.user.id;
      const historyid = item.historyid;
      if (userid && userid !== "") {
        formData.append("userid", userid);
      }
      if (historyid && historyid !== "") {
        formData.append("historyid", item.historyid);
      }
      this.$http({
        method: "post",
        url: "updateEquipmentHistory.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json"
          }
        }
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (response && response.data && response.data.success) {
            let msg = "";
              msg =
                "Das Gerät wurde Ihnen zugewiesen.";

            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.dialog = false;
          } else {
            const msg =
              "Das Gerät konnte ihenn nicht zugewiesen werden.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function(error) {
          error;
        });
    }
  }
};
</script>